export const contents = [
  {
    title: "World's first 100% \nDecentralize PVP casino",
    contents: [
      "Play with any token",
      "No account & No deposit",
      "Instant reward",
      "5 engaging games",
      "Chance to own your room",
    ],
    action: {
      title: "Play Now",
      link: "REACT_APP_GAME_URL",
    },
  },
];
