import { Space } from "antd";

const Header = () => {
  return (
    <Space className="app-header">
      <img src="/assets/logo.png" alt="logo" width={128} />
      <Space>
        <a
          href={process.env.REACT_APP_TWITTER_URL}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="social-icon"
            src="/assets/twitter-icon.png"
            alt="twitter-icon"
            width={32}
          />
        </a>
        <a
          href={process.env.REACT_APP_DISCORD_URL}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="social-icon"
            src="/assets/discord-icon.png"
            alt="discord-icon"
          />
        </a>
        <a
          href={process.env.REACT_APP_TELEGRAM_URL}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="social-icon"
            src="/assets/telegram-icon.png"
            alt="telegram-icon"
            width={32}
            style={{
              borderRadius: "100%",
            }}
          />
        </a>
      </Space>
    </Space>
  );
};

export default Header;
